<template>
  <div id="student" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form @submit.prevent="passes(submit)">
            <SdInput
              type="text"
              rules="required"
              label="Surname"
              v-model="student.surname"
            />
            <SdInput
              type="text"
              rules="required"
              label="Firstname"
              v-model="student.firstname"
            />
            <SdInput
              type="text"
              label="Othernames"
              v-model="student.othernames"
            />
            <SdSelect rules="required" label="Gender" v-model="student.gender">
              <option v-for="gender in genders" :key="gender.value">
                {{ gender.text }}
              </option>
            </SdSelect>

            <SdSelect
              rules="required"
              label="Religion"
              v-model="student.religion"
              :disabled="school.religion === 'ISLAM'"
            >
              <option v-for="religion in religions" :key="religion.value">
                {{ religion.text }}
              </option>
            </SdSelect>

            <b-field label="Date of Birth">
              <b-datepicker
                placeholder="Click to select..."
                icon="calendar-day"
                v-model="student.dateOfBirth"
                :date-formatter="(date) => date.toDateString()"
                :date-parser="(date) => new Date(date)"
              ></b-datepicker>
            </b-field>

            <SdSelect
              rules="required"
              label="School Class"
              v-model="student.schoolClass.name"
              v-if="$route.params.id === 'new'"
            >
              <option
                v-for="schoolClass in schoolClasses"
                :key="schoolClass.name"
              >
                {{ schoolClass.name }}
              </option>
            </SdSelect>

            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized is-pulled-right mt-3"
            >
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm, fetchSchool } from '@/assets/js/app_info.js'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'student',
  data() {
    return {
      school: {},
      schoolId: null,
      termId: null,
      pageTitle: 'Student',
      genders: [
        { value: 'Female', text: 'Female' },
        { value: 'Male', text: 'Male' },
      ],
      religions: [
        { value: 'Christianity', text: 'Christianity' },
        { value: 'Islam', text: 'Islam' },
        { value: 'Others', text: 'Others' },
      ],
      student: {
        id: null,
        surname: '',
        firstname: '',
        othernames: '',
        gender: null,
        religion: null,
        dateOfBirth: new Date(),
        schoolClassId: null,
        schoolClass: {
          name: null,
        },
      },
      schoolClasses: [],
      menus: [],
    }
  },
  apollo: {
    schoolClasses: {
      query() {
        if (this.$route.params.school_id) {
          return SCHOOL_CLASSES
        }
      },
      variables() {
        return { schoolId: parseInt(this.$route.params.school_id) }
      },
    },
  },
  watch: {
    school(data) {
      if (!data) return
      if (data.religion === 'ISLAM') {
        this.student.religion = 'Islam'
      }
    },
    student(data) {
      this.menus.push({
        name: 'Student Info',
        route: `/school/${this.schoolId}/school_class/${data.schoolClassId}/student_info/${data.id}`,
      })
      this.$store.commit('setSubMenus', this.menus)
    },
    'student.dateOfBirth'(newValue) {
      if (!newValue) return
      if (typeof newValue === 'string') {
        this.student.dateOfBirth = new Date(newValue)
      }
    },
    'student.schoolClass.name'(value) {
      if (this.schoolClasses.length == 0) return
      if (typeof value === 'string') {
        this.student.schoolClassId = this.schoolClasses.filter(
          (school_class) => school_class.name == value
        )[0].id

        this.menus.push({
          name: 'Students In Class',
          route: `/school/${this.schoolId}/students/${this.student.schoolClassId}`,
        })
        this.$store.commit('setSubMenus', this.menus)
      }
    },
  },
  methods: {
    submit() {
      const surname = this.student.surname.trim()
      const firstname = this.student.firstname.trim()
      const othernames = this.student.othernames
        ? this.student.othernames.trim()
        : null
      const gender = this.student.gender
      const religion = this.student.religion
      const dateOfBirth = this.student.dateOfBirth
      const schoolClassId = this.student.schoolClassId

      if (this.$route.params.id === 'new') {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createStudent(
                $surname: String!
                $firstname: String!
                $othernames: String
                $gender: String!
                $religion: String!
                $dateOfBirth: String!
                $schoolClassId: Int!
                $termId: Int!
              ) {
                createStudent(
                  input: {
                    surname: $surname
                    firstname: $firstname
                    othernames: $othernames
                    gender: $gender
                    religion: $religion
                    dateOfBirth: $dateOfBirth
                    schoolClassId: $schoolClassId
                    termId: $termId
                  }
                ) {
                  student {
                    surname
                    firstname
                    othernames
                    gender
                    religion
                    dateOfBirth
                    schoolClassId
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              surname: surname,
              firstname: firstname,
              othernames: othernames,
              gender: gender,
              religion: religion,
              dateOfBirth: dateOfBirth,
              schoolClassId: parseInt(schoolClassId),
              termId: parseInt(this.termId),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createStudent.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.schoolId}/students/${schoolClassId}`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        const id = parseInt(this.student.id)
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateStudent(
                $id: Int!
                $surname: String!
                $firstname: String!
                $othernames: String
                $gender: String!
                $religion: String!
                $dateOfBirth: String!
                $schoolClassId: Int!
              ) {
                updateStudent(
                  input: {
                    id: $id
                    surname: $surname
                    firstname: $firstname
                    othernames: $othernames
                    gender: $gender
                    religion: $religion
                    dateOfBirth: $dateOfBirth
                    schoolClassId: $schoolClassId
                  }
                ) {
                  student {
                    id
                    surname
                    firstname
                    othernames
                    gender
                    religion
                    dateOfBirth
                    schoolClassId
                  }
                  errors
                }
              }
            `,
            variables: {
              id: id,
              surname: surname,
              firstname: firstname,
              othernames: othernames,
              gender: gender,
              religion: religion,
              dateOfBirth: dateOfBirth,
              schoolClassId: parseInt(schoolClassId),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateStudent.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.schoolId}/students/${schoolClassId}`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.$apollo.addSmartQuery('student', {
        query() {
          if (this.$route.params.id) {
            return gql`
              query studentId($id: ID!) {
                student(id: $id) {
                  id
                  surname
                  firstname
                  othernames
                  gender
                  religion
                  dateOfBirth
                  schoolClassId
                  schoolClass {
                    name
                  }
                }
              }
            `
          }
        },
        variables() {
          return { id: this.$route.params.id }
        },
        fetchPolicy: 'no-cache',
      })
    }
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchTerm().then((term) => {
      this.termId = term.id
    })

    fetchSchool().then((school) => {
      this.school = school
    })

    this.menus.push({
      name: 'Students In School',
      route: `/school/${this.schoolId}/students_in_school`,
    })

    this.$store.commit('setSubMenus', this.menus)
  },
}
</script>

<style lang="scss" scoped></style>
